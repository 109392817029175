<template>
  <div class="home">
    <el-row type="flex" :gutter="20">
        <el-col
          :xs="{ offset: 0, span: 24 }"
          :sm="{ offset: 4, span: 16 }"
          :md="{ offset: 8, span: 8 }"
        >
            <el-form
                :model="dynamicValidateForm"
                ref="loginForm"
                class="login-form"
                v-loading="inProgress"
                @submit.native.prevent="submitForm"
            >
            <p>Пријави се</p>
                <el-form-item
                    prop="email"
                    label="Електронска адреса"
                    :rules="[
                    { required: true, message: 'Унесите валидну ел. адресу', trigger: 'blur' },
                    { type: 'email', message: 'Унесите валидну ел. адресу', trigger: ['blur', 'change'] }
                    ]"
                >
                    <el-input
                      v-model.trim="dynamicValidateForm.email"
                      autocapitalize="none"
                      autocorrect="off"
                      spellcheck="false"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="password"
                    label="Лозинка"
                    :rules="[
                    { required: true, message: 'Унесите валидну лозинку', trigger: 'blur' },
                    ]"
                >
                    <el-input v-model.trim="dynamicValidateForm.password" type="password"></el-input>
                </el-form-item>
                 <el-form-item>
                    <el-button
                      native-type="submit"
                      type="primary"
                      @click="submitForm"
                    >Пријави се</el-button>
                    <el-button
                      type="default"
                      @click="$router.push('register')"
                      class=""
                    >Направи налог
                    </el-button>
                </el-form-item>
            </el-form>
        </el-col>
    </el-row>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "contact",
  data() {
    return {
      dynamicValidateForm: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      inProgress: "isRequestInProgress"
    })
  },
  methods: {
    ...mapActions({
      login: "login"
    }),
    submitForm() {
      this.$refs["loginForm"].validate(valid => {
        if (!valid) {
          return;
        }
        this.login(this.dynamicValidateForm);
      });
    }
  }
};
</script>

<style lang="scss">
.contact-form {
  width: 50vw;
}
</style>
